import { MouseEvent, useEffect, useState } from "react";
import { MediaCategoriesContainer } from "../media-categories/MediaCategoriesContainer";
import { Separator, StyledButton, StyledContentContainer } from "./styled-components";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
// noinspection ES6PreferShortImport
import { CategoryLinkAnalyticsContext } from "../media-categories/CategoryLinkAnalyticsContext";
import { NoLinkIndicatorProps } from "./NoLinkIndicator";
import { Popover } from "@kaltura/ds-react-components";

interface LinkIndicatorProps extends NoLinkIndicatorProps {
    entryId: string;
    showWarning: boolean;
}

/**
 * status indicator for cases where the indicator should act as a link
 */
const LinkIndicator = (props: LinkIndicatorProps) => {

    const {icon, label, entryId} = props;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        sendButtonAnalytics("card - Publish indicator", ButtonClickAnalyticsType.EXPAND);
        setAnchorEl(event.currentTarget);
        setIsLoading(true);
    };

    const handleCategoryLinkAnalytics = () => {
        sendButtonAnalytics(
            "Publish - Publish indicator modal box - Category list -  Link to category",
            ButtonClickAnalyticsType.LINK,
            entryId
        );
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsLoading(false);
    };

    const handleDataLoaded = () => {
        setIsLoading(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (!isLoading && open && anchorEl) {
            // Recalculate popover position when data is loaded
            setAnchorEl(anchorEl);
        }
    }, [isLoading, open, anchorEl]);

    return (
        <>
            <StyledButton
                aria-describedby={id}
                onClick={handleClick}
                variant={"borderless"}
            >
                {icon}<Separator />{label}
            </StyledButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <StyledContentContainer>
                      <CategoryLinkAnalyticsContext.Provider
                            value={{
                                sendCategoryLinkAnalytics: handleCategoryLinkAnalytics,
                                sendCategoryBubbleAnalytics: () => {},
                            }}
                        >
                        <MediaCategoriesContainer
                            onDataLoaded={handleDataLoaded}
                            showExternal={true}
                            entryId={entryId}
                        />
                    </CategoryLinkAnalyticsContext.Provider>
                </StyledContentContainer>
            </Popover>
        </>
    );
}

export default LinkIndicator;
