import { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { BaseInput, Typography } from "@kaltura/ds-react-components";

export const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.danger.main,
    boxSizing: "border-box",
    marginTop: theme.spacing(1)
}));

export const StyledInput = styled(BaseInput)<{ error?: boolean }>(
    ({ theme, error }) => ({
        width: "100%",
        ...(error && {
            /* Acknowledgement/Danger */
            border: `1px solid ${theme.kaltura.palette.danger.main}`,
        }),
    })
);

export const FormFieldError = ({ children }: PropsWithChildren) => {
    return (
        <div className={"kms-ds-form-field-error"}>
            <ErrorText variant={"body2"}>{children}</ErrorText>
        </div>
    );
};
