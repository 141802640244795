import { KmsTypeFilterBase } from "./KmsTypeFilterBase";
import { KmsTypeFilterItemGroup, KmsTypeFilterItemRadio, KmsTypeFilterItemRange } from "./item";

/**
 * ts implementation of Kms_Type_Esearch_Filter_Radio
 */

export interface KmsTypeFilterRadio extends KmsTypeFilterBase<KmsTypeFilterItemRadio | KmsTypeFilterItemGroup | KmsTypeFilterItemRange> {
    singleSelection: true;
}

// noinspection PointlessBooleanExpressionJS
export const isKmsTypeFilterRadio = (value: KmsTypeFilterBase<any>): value is KmsTypeFilterRadio =>
    (value as KmsTypeFilterRadio).singleSelection === true;
