import {
    CncProps,
    FloatedCnc,
    EmbeddedCncContainer,
    EmbeddedCncSidebarBottomButtons,
    EmbeddedCncReactionsDesktop,
    EmbeddedCncReactionsMobile,
    EmbeddedCncSidebarButton
} from "@mediaspace/features/cnc";
import { createRoot } from "react-dom/client";

export const Floated = {
    render: (props: CncProps) => {
        const rootElement = document.createElement("div");
        document.body.append(rootElement);

        const root = createRoot(rootElement);
        root.render(<FloatedCnc {...props} />);
    },
};

export const Embedded = {
    Container: EmbeddedCncContainer,
    SidebarButtons: {
        Top: EmbeddedCncSidebarButton,
        Bottom: EmbeddedCncSidebarBottomButtons,
    },
    Reactions: {
        Desktop: EmbeddedCncReactionsDesktop,
        Mobile: EmbeddedCncReactionsMobile,
    },
};
