import { createRoot } from "react-dom/client";
import React, { Suspense } from "react";
import type { HeaderMenuProps, NavigationPanelProps } from "@mediaspace/header-menu";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HighContrastMenuItem as item } from "@mediaspace/header-menu";

export const HighContrastMenuItem = item;

const HeaderMenu = React.lazy(() => import("@mediaspace/header-menu"));

export const Component = HeaderMenu;

export const NavigationPanel = React.lazy(
    () => import("@mediaspace/header-menu/SidebarMenu/NavigationPanel")
)

export const render = (
    id: string,
    props: {headerProps: HeaderMenuProps, sidebarProps: NavigationPanelProps} & { context: Config }
) => {
    const { theming } = props.context;
    const root = createRoot(document.getElementById(id)!);
    root.render(
        <Suspense fallback={<div/>}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode} scoped={true}>
                    <HeaderMenu {...props.headerProps} />
                    <NavigationPanel {...props.sidebarProps} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>
    );
};

