import React, { useState } from "react";
import { baseUrl, postKmsData, translate } from "@mediaspace/shared/utils";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useToastsContext } from "@mediaspace/shared/ui";
import { PublishStatus, PublishSaveRequestData, CrossAppUnpublishRequestData } from "@mediaspace/shared/types";
import { PrivateModal } from "./private-modal/PrivateModal";
import { UnlistedModal } from "./unlisted-modal/UnlistedModal";
import { PublishedModalContainer } from "./published-modal/PublishedModalContainer";
import { ExternalUnpublishConfirmModal } from "./external-unpublish-confirm-modal/ExternalUnpublishConfirmModal";
import { PublishSaveResponseData } from "@mediaspace/shared/types/publish/PublishSaveResponseData";
import { SaveType } from "./../../utils";

export interface ModalManagerProps {
	entryIds: string[];
	publishStatus: PublishStatus;
	targetStatus: PublishStatus;
	crossApplicationUnpublishing: boolean;
	onSubmit: (result: PublishSaveResponseData) => void;
	onClose: () => void;
}

/**
 * Publish Menu Item selection Modal Manager - select the correct modal by publish state,
 * and communicate with KMS
 */
export function ModalManager(props: ModalManagerProps) {
	const { entryIds, publishStatus, targetStatus, crossApplicationUnpublishing, onClose, onSubmit } = props;

	const [showExternalConfirm, setShowExternalConfirm] = useState(false);

	const { showToast } = useToastsContext();

	// submit save or crossApplicationUnpublish to kms
	const handleSubmit = async (data: PublishSaveRequestData | CrossAppUnpublishRequestData, saveType = SaveType.save) => {
        // set save url by save type
        // save - private/unlisted/publish
        // or cross-application-unpublish
        const url = saveType === SaveType.save
            ? `${baseUrl}/publishmedia/media/save/`
            : `${baseUrl}/publishmedia/media/cross-application-unpublish/`;
		// send data to kms
        try {
            const result = await postKmsData(url, data);
            // show toast
            const severity = result.success ? "success" : "error";
            if (result.messages) {
                for (const message of result.messages as string[]) {
                    showToast({ severity: severity, message: message });
                }
            }
            // update the menu state
            onSubmit(result);
        } catch (e) {
            showToast({ severity: "error", message: translate("Error updating media") });
            // close modal
            onClose();
        }
	};

	const handleUnpublishExternal = () => {
		setShowExternalConfirm(true);
	};

	switch (targetStatus) {
		case PublishStatus.UNLISTED:
			return (
				<UnlistedModal
					entryIds={entryIds}
					publishStatus={publishStatus}
					onSubmit={handleSubmit}
					onClose={onClose}
				/>
			);
		case PublishStatus.PRIVATE:
			return (
				<PrivateModal
					entryIds={entryIds}
					publishStatus={publishStatus}
					onSubmit={handleSubmit}
					onClose={onClose}
				/>
			);
		case PublishStatus.PUBLISHED:
			if (!showExternalConfirm) {
				return (
					<PublishedModalContainer
						entryIds={entryIds}
						publishStatus={publishStatus}
						crossApplicationUnpublishing={crossApplicationUnpublishing}
						onUnpublishExternal={handleUnpublishExternal}
						onSubmit={handleSubmit}
						onClose={onClose}
					/>
				);
			} else {
				return (
					<ExternalUnpublishConfirmModal
						entryIds={entryIds}
						publishStatus={publishStatus}
						onSubmit={handleSubmit}
						onClose={onClose}
					/>
				);
			}
		default:
			return null;
	}
}
