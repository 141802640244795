import styled from "@emotion/styled";
import {EntryListItemActionProps} from "./EntryListItemAction";
import React, {useContext} from "react";
import ReactHtmlParser from "../html-react-parser";
import {Config, ConfigContext} from "@mediaspace/shared/data-kms-config";
import {getEntryUrl, stripTags, translate, useMediaQuery} from "@mediaspace/shared/utils";
// noinspection ES6PreferShortImport
import {Thumbnail} from "../thumbnail/Thumbnail";
import {Truncate} from "../truncate/Truncate";
import { Badge, Tooltip, Typography } from "@kaltura/ds-react-components";
import EntryListItemActions from "./EntryListItemActions";
import {useTheme} from "@mediaspace/shared/styled";
import { KmsTypeSchedulingTimeInterval } from "@mediaspace/shared/types";
import { useCurrentTimeForAnimation, useDateFormat } from "@mediaspace/hooks";
// noinspection ES6PreferShortImport
import { StatusLive } from "../status-live/StatusLive";


export interface EntryListItemProps {
    /**
     * entry name
     */
    name: string;
    /**
     * entry description
     */
    description: string;
    /**
     * entry thumbnail url
     */
    image?: string;
    /**
     * entry duration in seconds
     */
    duration?: number;
    entryId: string;
    schedulingData?: KmsTypeSchedulingTimeInterval;
    actions?: EntryListItemActionProps[];
}

const EntryItem = styled("div")(({theme} ) => ({
    display: "flex",
    borderRadius: theme.kaltura.shape.roundness1,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.kaltura.palette.tone7,
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        margin: "16px 0 0 0",
        borderRadius: 4,
    },
}));

const ItemDataContainer = styled("div")(({theme} ) => ({
    display: "flex",
    flexDirection: "row",
    flex: "1 1 0",
    minWidth: 0,
    padding: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
        display: "block",
    },

}));

const ItemData = styled("div")(({theme} ) => ({
    width: "100%",
    paddingRight: 95,
    [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
    },
}));

const StyledThumbnail = styled(Thumbnail)(({theme} ) => {
    const isTinyScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return {
    width: 249,
    height: 140,
    backgroundColor: theme.palette.common.black,
    [`&, & img`]: {
        borderRadius: isTinyScreen
            ? `${theme.kaltura.shape.roundness1}px ${theme.kaltura.shape.roundness1}px 0 0`
            : `${theme.kaltura.shape.roundness1}px 0 0 ${theme.kaltura.shape.roundness1}px`,
        verticalAlign: 'middle'
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "auto",
    },
}});

const EntryTitle = styled(Typography<"a">)(({theme}) => ({
    color: theme.kaltura.palette.tone1,
    textDecoration: "none",
}));

const TruncatedName = styled(Truncate)({
    // Don't take the full width if not necessary, so that the tooltip will be aligned properly
    display: "inline-block",
});

const TruncatedDescription = styled(Truncate)(({theme}) => ({
    // The ellipsis ("...") will inherit this color
    color: theme.kaltura.palette.tone2,
}));

const EntryDescription = styled(Typography)(({theme}) => ({
    padding: theme.spacing(2, 0, 0),
}));

const EntryDescriptionSeparator = styled("div")(({theme}) => ({
    height: theme.spacing(2),
}));

const EntrySchedulingLine = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    color: theme.kaltura.palette.tone3,
}));

const EntryItemRemove = styled("div")(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(0.8),
    },
}));

/**
 * Entry List Item component
 * Displays entry data, either scheduled or not, without scheduling info.
 */
function EntryListItem({
                           name,
                           description,
                           image = "",
                           duration,
                           entryId,
                           schedulingData,
                           actions,
                       }: EntryListItemProps) {
    const config: Config = useContext(ConfigContext);
    const enableEntryTitles = config?.application?.enableEntryTitles;
    const entryName = enableEntryTitles ? name : undefined;
    const linkUrl = getEntryUrl(entryId, undefined, entryName);

    const theme = useTheme();
    const isTinyScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const now = useCurrentTimeForAnimation(undefined, 1000) / 1000;
    const isLive = !!schedulingData && schedulingData.start.timestamp <= now && schedulingData.end.timestamp >= now;
    const { startDay, formattedScheduledStartTime, formattedScheduledEndTime } = useDateFormat(
        (schedulingData?.start.timestamp ?? 0) * 1000,
        (schedulingData?.end.timestamp ?? 0) * 1000,
        undefined,
        true,
    );
    const formattedTime = schedulingData ? `${startDay}, ${formattedScheduledStartTime} - ${formattedScheduledEndTime}` : undefined;

    // use memo to render description to prevent re-rendering on viewport change, like tabbing
    const truncateDescription = React.useMemo(() => {
        if (!description && !formattedTime) {
            return null;
        }

        const contents = <>
            <EntryDescriptionSeparator/>
            {formattedTime && <EntrySchedulingLine>
                <Typography variant={"body2Highlight"} className="kms-ds-entry-list-item-scheduling">{formattedTime}</Typography>
                {isLive ? <StatusLive>{translate("Live")}</StatusLive> : <Badge size={"small"} label={translate("Scheduled")}/>}
            </EntrySchedulingLine>}
            {!!description && <EntryDescription variant={"body2"} component={"span"} className="kms-ds-entry-list-item-text-description">
                {ReactHtmlParser(stripTags(description))}
            </EntryDescription>}
        </>;

        if (isTinyScreen) {
            return contents;
        }

        return (
            <TruncatedDescription lines={3} showMore={false}>
                {contents}
            </TruncatedDescription>
        );
    }, [description, formattedTime, isLive, isTinyScreen]);

    return (
        <EntryItem className="kms-ds-entry-list-item">
            <StyledThumbnail
                thumbnailUrl={image}
                duration={duration}
                linkUrl={linkUrl}
                ariaLabelText={translate("thumbnail for %1", [name])}
            />
            <ItemDataContainer className="kms-ds-entry-list-item-content">
                <ItemData className="kms-ds-entry-list-item-text">
                    <Tooltip placement={"top"} title={name}>
                        <EntryTitle variant={"h4"} component={"a"} href={linkUrl} className="kms-ds-entry-list-item-text-title">
                            {isTinyScreen ? name : <TruncatedName lines={2} showMore={false}>{name}</TruncatedName>}
                        </EntryTitle>
                    </Tooltip>
                    {truncateDescription}
                </ItemData>
                {actions && (
                    <EntryItemRemove className="kms-ds-entry-list-item-remove">
                        <EntryListItemActions actions={actions} />
                    </EntryItemRemove>
                )}
            </ItemDataContainer>
        </EntryItem>
    );
}

export default EntryListItem;
