import { KmsTypeFilterItemBase } from "./KmsTypeFilterItemBase";

/**
 * ts implementation of Kms_Type_Esearch_Filter_Items_Checkbox
 */
export interface KmsTypeFilterItemCheckbox extends KmsTypeFilterItemBase {
	/**
	 * boolean - is this filter item a checkbox
	 */
	checkbox: true;

	/**
	 * boolean - is this filter item checked
	 */
	checked?: boolean;

	/**
	 * boolean - is this filter item equalls all items checked
	 */
	equalsAll?: boolean;

	/**
	 * string label to be used in bubbles
	 */
	bubbleLabel?: string;
}

// noinspection PointlessBooleanExpressionJS
export const isKmsTypeFilterItemCheckbox = (value: KmsTypeFilterItemBase<any>): value is KmsTypeFilterItemCheckbox =>
    (value as KmsTypeFilterItemCheckbox).checkbox === true;
