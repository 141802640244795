import { ESupportedActions } from "@kaltura/ds-react-richtext";
// eslint-disable-next-line @nx/enforce-module-boundaries
import TextField from "@mediaspace/shared/ui/controlled-form/TextField";
// eslint-disable-next-line @nx/enforce-module-boundaries
import TagsField from "@mediaspace/shared/ui/controlled-form/TagsField";
// eslint-disable-next-line @nx/enforce-module-boundaries
import RichtextField from "@mediaspace/shared/ui/controlled-form/RichtextField";
import { EditFormElement } from "@mediaspace/shared/upload";
import { FormData } from "./EditForm";
import { useConfig } from "@mediaspace/shared/data-kms-config";
// eslint-disable-next-line @nx/enforce-module-boundaries
import DateField from "@mediaspace/shared/ui/controlled-form/DateField";
// eslint-disable-next-line @nx/enforce-module-boundaries
import DateWithTimeField from "@mediaspace/shared/ui/controlled-form/DateWithTimeField";
// eslint-disable-next-line @nx/enforce-module-boundaries
import MultiTextField from "@mediaspace/shared/ui/controlled-form/MultiTextField";
// eslint-disable-next-line @nx/enforce-module-boundaries
import MultiSelectField from "@mediaspace/shared/ui/controlled-form/MultiSelectField";
// eslint-disable-next-line @nx/enforce-module-boundaries
import SelectField from "@mediaspace/shared/ui/controlled-form/SelectField";

export const Field = (field: EditFormElement, data?: FormData) => {
    const config = useConfig();

    switch (field.fieldType) {
        case "autocomplete_element":
            return (
                <TagsField
                    url={field.url || ""}
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
					value={data ? data[field.id] as string : ""}
                    label={field.label}
                    description={field.description}
                />
            );
        case "rich_text_element":
            return (
                <RichtextField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
					value={data ? data[field.id] as string : ""}
                    description={field.description}
                    supportedActions={[
                        ESupportedActions.Bold,
                        ESupportedActions.Italic,
                        ESupportedActions.Underline,
                        ESupportedActions.List,
                        ESupportedActions.Link,
                        ESupportedActions.Image
                    ]}
                />
            );
        case "text_element":
            return (
                <TextField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
                    required={field.required}
					defaultValue={data ? data[field.id] as string : ""}
                    description={field.description}
                />
            );
        case "date_element":
            return (
                <DateField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
					value={data ? data[field.id] as string : ""}
                    description={field.description}
                    dateFormat={config.application.shortDateFormat}
                />
            );
        case "date_time_element":
            return (
                <DateWithTimeField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
					value={data ? data[field.id] as string : ""}
                    description={field.description}
                    dateFormat={config.application.shortDateFormat}
                />
            );
        case "multi_text_element":
            return (
                <MultiTextField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
					value={data ? data[field.id] as string[] : []}
                    description={field.description}
                />
            );
        case "multi_select_list_element":
            return (
                <MultiSelectField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
					value={data ? data[field.id] as string[] : []}
                    description={field.description}
                    options={field.options || []}
                />
            );
        case "list_element":
            return (
                <SelectField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
					value={data ? data[field.id] as string : undefined}
                    description={field.description}
                    options={(field.options || []).map(opt => {
                        return {label: opt, value: opt}
                    })}
                />
            );
        default:
            return null;
    }
};
