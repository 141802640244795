import React, { Suspense, useEffect, useMemo } from "react";
import { MediaCategoriesSkeleton } from "./media-categories-skeleton/MediaCategoriesSkeleton";
import { baseUrl, fetchKmsData } from "@mediaspace/shared/utils";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ErrorBoundary } from "@mediaspace/shared/ui";
import { KmsTypePublishEntryCategories } from "@mediaspace/shared/types";
import { PublishedCategories } from "./published-categories/PublishedCategories";
import { ExternalPublishedCategories } from "./external-published-categories/ExternalPublishedCategories";
import styled from "@emotion/styled";

export interface MediaCategoriesInnerProps {
    fetchPublishLocations: () => {
        entryCategories?: KmsTypePublishEntryCategories;
    };
    onDataLoaded?: () => void;
}

/**
 * Media Categories content
 */
function MediaCategories(props: MediaCategoriesInnerProps) {
    const { fetchPublishLocations, onDataLoaded } = props;
    const locations = fetchPublishLocations();

    useEffect(() => {
        onDataLoaded?.();
    }, [onDataLoaded]);

    const { entryCategories } = locations;

    return (
        <StyledMediaCategoriesContainer
            className="kms-ds-publish-media-categories">
            {entryCategories && <PublishedCategories entryCategories={entryCategories} />}
            {!!entryCategories?.externalCategories?.length && (
                <ExternalPublishedCategories externalCategories={entryCategories?.externalCategories} />
            )}
        </StyledMediaCategoriesContainer>
    );
}

export interface MediaCategoriesContainerProps {
    entryId: string;
    showExternal?: boolean;
    onDataLoaded?: () => void;
}

/**
 * Media Categories suspense container - fetch the media publish locations,
 * and renders them on the page (private and unlisted modals, my media items)
 */
export const MediaCategoriesContainer = (props: MediaCategoriesContainerProps) => {
    const { entryId, showExternal = false, onDataLoaded } = props;

    // fetch data from kms
    const fetchPublishLocations = useMemo(
        () => fetchKmsData<{ entryCategories?: KmsTypePublishEntryCategories }>(
            baseUrl + "/publishmedia/media/get-categories/",
            {
                entryId: entryId,
                showExternal: showExternal,
            }
        ),
        [entryId, showExternal]
    );

    // render with Suspense
    return (
        <ErrorBoundary fallback={<MediaCategoriesSkeleton />}>
            <Suspense fallback={<MediaCategoriesSkeleton />}>
                <MediaCategories
                    fetchPublishLocations={fetchPublishLocations}
                    onDataLoaded={onDataLoaded}
                ></MediaCategories>
            </Suspense>
        </ErrorBoundary>
    );
};

const StyledMediaCategoriesContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
    "& > *:last-child": {
        marginBottom: 0,
    }
}));

