import {useState} from "react";
import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import Collapse from "@mui/material/Collapse";
import {ChevronDown16Icon, ChevronUp16Icon} from "@kaltura/ds-react-icons";
import Options from "./Options";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {OptionProps} from "@mediaspace/features/channels-collections";

export interface FilterProps {
	className?: string;
	label: string;
	options: OptionProps[];
	onFilterUpdate: (value: string) => void;
	isModal: boolean;
}

const StyledWrapper = styled("div")(({ theme }) => ({
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        marginRight: theme.spacing(5)
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        marginRight: theme.spacing(7.5)
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        marginRight: theme.spacing(12.5)
    }
}));

const StyledTitleWrapper = styled("div", {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "isModal";
    }
})<{ isModal: boolean }>(({ theme, isModal }) => ({
    display: isModal ? "flex" : "inline-block",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2.5)
}));

const StyledTitle = styled(Typography)({
    textTransform: "capitalize"
});

// filters group should be open if at least one option is checked
const checkIfOpen = (options: OptionProps[]) => {
    return options.some(option => {
        return option.checked;
    });
};

/**
 * Channelscollections single Filter
 */
const Filter = ({
    className,
    label,
    options,
    onFilterUpdate,
    isModal = false
}: FilterProps) => {

    const [isOpen, setIsOpen] = useState(checkIfOpen(options));

    const handleTitleClick = () => {
        setIsOpen(prevState => !prevState)
    }

	return (
		<StyledWrapper className={className}>
            <StyledTitleWrapper
                isModal={isModal}
                onClick={handleTitleClick}
            >
                <StyledTitle variant="body1Highlight">
                    {label}
                </StyledTitle>
                {isModal && (isOpen ? <ChevronUp16Icon /> : <ChevronDown16Icon />)}
            </StyledTitleWrapper>
            {isModal ? (
                <Collapse
                    in={isOpen}
                    collapsedSize={0}
                >
                    <Options
                        options={options}
                        onFilterUpdate={onFilterUpdate}
                    />
                </Collapse>
            ) : (
                <div>
                    <Options
                        options={options}
                        onFilterUpdate={onFilterUpdate}
                    />
                </div>
            )}
		</StyledWrapper>
	);
}

export default Filter;
