import React from "react";
import { getStandaloneComponentRenderer } from "@mediaspace/features/layout";

const StatusScreen = React.lazy(
    () => import("@mediaspace-react-components/features/email-login/status-screen/StatusScreen")
);

const EnterSiteScreen = React.lazy(
    () => import("@mediaspace-react-components/features/email-login/enter-site-screen/EnterSiteScreen")
);

export const renderStatus = getStandaloneComponentRenderer(StatusScreen);

export const renderEnterSite = getStandaloneComponentRenderer(EnterSiteScreen);
