import {PublishData, PublishStatus} from "@mediaspace/shared/types";
import { Globe16Icon, Link16Icon, Lock16Icon, WarningTriangle16Icon } from "@kaltura/ds-react-icons";
import {translate} from "@mediaspace/shared/utils";
import LinkIndicator from "./LinkIndicator";
import NoLinkIndicator from "./NoLinkIndicator";

export interface PublishIndicatorProps {
    publishData: PublishData;
    entryId: string;
}

const PublishIndicator = (props: PublishIndicatorProps) => {
    const {publishData, entryId} = props;

    const {status, showWarning} = publishData;

    const statusMap = {
        [PublishStatus.PRIVATE]: {
            icon: showWarning ? <WarningTriangle16Icon /> : <Lock16Icon />,
            label: translate("Private")
        },
        [PublishStatus.UNLISTED]: {
            icon: showWarning ? <WarningTriangle16Icon /> : <Link16Icon />,
            label: translate("Unlisted")
        },
        [PublishStatus.PUBLISHED]: {
            icon: showWarning ? <WarningTriangle16Icon /> : <Globe16Icon />,
            label: translate("Published")
        }
    }

    const showAsLink = showWarning || status === PublishStatus.PUBLISHED;

    return (
        <>
            {showAsLink && (
                <LinkIndicator
                    icon={statusMap[status].icon}
                    label={statusMap[status].label}
                    entryId={entryId}
                    showWarning={showWarning}
                />
            )}

            {!showAsLink && (
                <NoLinkIndicator
                    icon={statusMap[status].icon}
                    label={statusMap[status].label}
                />
            )}
        </>
    );
}

export default PublishIndicator;
