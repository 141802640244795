import styled from "@emotion/styled";

export const StatusLive = styled("div")(({ theme }) => ({
    display: "inline-block",
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    borderRadius: theme.kaltura.shape.roundness1,
    padding: 3,
    backgroundColor: theme.kaltura.palette.danger.main,
    textTransform: "uppercase",
}));
