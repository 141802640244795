import { useState } from "react";
import { KmsTypePublishUserCategories, KmsTypePublishCategories } from "@mediaspace/shared/types";
import { translate } from "@mediaspace/shared/utils";
import { PendingAlert } from "../pending-alert/PendingAlert";
import { CategorySearchInput } from "../category-search/CategorySearchInput";
import { ChannelsTabFormContainer } from "./ChannelsTabFormContainer";

export interface ChannelsTabProps {
    entryId: string;
    channels: KmsTypePublishCategories;
    userCategories: KmsTypePublishUserCategories;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    onPendingClick: () => void;
}

/**
 * publish modal channels tab
 */
export function ChannelsTab(props: ChannelsTabProps) {
    const { entryId, channels, onPendingClick } = props;

    const [keyword, setKeyword] = useState("");

    const handleSearch = (keyword: string) => {
        setKeyword(keyword);
    };

    const handleClearSearch = () => {
        setKeyword("");
    };

    return (
        <>
            <PendingAlert entryId={entryId} channels={channels} onPendingClick={onPendingClick} />

            <CategorySearchInput
                placeholder={translate("Search channels")}
                ariaLabel={translate("Enter text to search channels")}
                onSearch={handleSearch}
                onResetSearch={handleClearSearch}
            />

            <ChannelsTabFormContainer {...props} keyword={keyword} />
        </>
    );
}
