import { KmsTypeFilterItemBase } from "./KmsTypeFilterItemBase";

/**
 * ts implementation of Kms_Type_Esearch_Filter_Items_Radio
 */
export interface KmsTypeFilterItemRadio extends KmsTypeFilterItemBase {
	/**
	 * boolean - is this filter item a radio button
	 */
	radioButton: true;

	/**
	 * boolean - is this filter item checked
	 */
	checked?: boolean;

	/**
	 * string label to be used in bubbles
	 */
	bubbleLabel?: string;
}

// noinspection PointlessBooleanExpressionJS
export const isKmsTypeFilterItemRadio = (value: KmsTypeFilterItemBase<any>): value is KmsTypeFilterItemRadio =>
    (value as KmsTypeFilterItemRadio).radioButton === true;
