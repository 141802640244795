import { PropsWithChildren, useContext } from "react";
import { FieldError, MultipleFieldErrors } from "react-hook-form";
import { translate } from "@mediaspace/shared/utils";
import { Check16Icon, Close16Icon } from "@kaltura/ds-react-icons";
import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import clsx from "clsx";
import { ConfigContext } from "@mediaspace/shared/data-kms-config";
import {PasswordValidationType} from "@mediaspace/shared/types";

enum Strength {
    weak = "weak",
    medium = "medium",
    strong = "strong",
}

const PwdValidation = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(1)
}));

const PwdValidationField = styled("div", {
    shouldForwardProp: (prop) => prop !== "valid",
})<{ valid?: boolean }>(({ theme, valid }) => ({
    display: "flex",
    marginTop: theme.spacing(0.5),
    ...(valid && {
        color: theme.kaltura.palette.success.main,
    }),
    ...(!valid && {
        color: theme.kaltura.palette.danger.main,
    }),
}));

const TypographyValidation = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "valid",
})<{ valid?: boolean }>(({ theme, valid }) => ({
    ...(valid && {
        color: theme.kaltura.palette.success.main,
    }),
    ...(!valid && {
        color: theme.kaltura.palette.danger.main,
    }),
}));

const IconWrapper = styled.div(({ theme }) => ({
    marginRight: theme.spacing(0.5),
}));

export const passwordValidationFunction = (
    firstName: string,
    lastName: string,
    passwordValidations?: PasswordValidationType
) => {
    const result: Record<string, (value: string) => boolean> = {};

    if (!passwordValidations?.noRules && typeof passwordValidations?.validations === "object") {
        // populate validation tests list
        Object.entries(passwordValidations.validations).forEach(([rule, validator]) => {
            result[rule] = (value: string) => RegExp(validator['regex']).test(value);
        });
    }

    if (passwordValidations?.defaultRestrictions) {
        result["hasNames"] = (value: string) => {
            const firstNameValues = firstName ? firstName.trim().split(" ") : [];
            const lastNameValues = lastName ? lastName.trim().split(" ") : [];
            const nameValues = firstNameValues.concat(lastNameValues);
            return !nameValues.some((element: string) => {
                return (
                    element.length > 2 &&
                    value.toLowerCase().includes(element.toLowerCase())
                );
            });
        };
    }

    return result;
};

/**
 * single field validation status
 */
const ValidationStatus = ({
    valid,
    children,
}: PropsWithChildren<{ valid: boolean }>) => {
    return (
        <PwdValidationField
            valid={valid}
            className={clsx(
                "kms-ds-password-field-validation-status",
                valid && "kms-ds-password-field-validation-status-valid"
            )}
        >
            <IconWrapper>
                {valid && (
                    <Check16Icon
                        aria-label={translate("condition met")}
                        aria-hidden={false}
                    />
                )}
                {!valid && (
                    <Close16Icon
                        aria-label={translate("condition not met")}
                        aria-hidden={false}
                    />
                )}
            </IconWrapper>
            {children}
        </PwdValidationField>
    );
};

/**
 * Password validation indication
 */
export interface PasswordValidationProps {
    errors?: FieldError;
}
export const PasswordValidation = ({ errors }: PasswordValidationProps) => {
    const nameInValid = errors?.types?.hasNames;

    const config = useContext(ConfigContext);

    const validations: MultipleFieldErrors = {};
    if (!config.passwordValidations?.noRules && typeof config.passwordValidations?.validations === "object") {
        Object.keys(config.passwordValidations.validations).forEach(validation => {
            validations[validation] = errors?.types && errors?.types[validation] ? errors.types[validation] : undefined;
        });
    }

    const passwordStrength = (validCount: number) => {
        if (validCount > 4) {
            return Strength.strong;
        }
        if (validCount > 3) {
            return Strength.medium;
        }
        return Strength.weak;
    };

    const validCount =
        5 - (errors?.types ? Object.keys(errors?.types).length : 0);
    const strength = passwordStrength(validCount);

    const noRules = config?.passwordValidations?.noRules;

    return (
        <PwdValidation className="kms-ds-password-field-validation">
            {strength !== Strength.strong && (
                <PwdValidationField
                    valid={false}
                    className="kms-ds-form-field-error"
                >
                    <TypographyValidation variant={"body2"} valid={false}>
                        {translate("Please enter a valid password")}
                    </TypographyValidation>
                </PwdValidationField>
            )}

            {!noRules && (
                <ValidationStatus valid={strength === Strength.strong}>
                    <TypographyValidation
                        variant={"body2"}
                        valid={strength === Strength.strong}
                    >
                        {translate("Password strength: ")}&nbsp;
                    </TypographyValidation>
                    {strength === Strength.weak && (
                        <TypographyValidation variant={"body2"} valid={false}>
                            {translate("weak")}
                        </TypographyValidation>
                    )}
                    {strength === Strength.medium && (
                        <TypographyValidation variant={"body2"} valid={false}>
                            {translate("medium")}
                        </TypographyValidation>
                    )}
                    {strength === Strength.strong && (
                        <TypographyValidation variant={"body2"} valid={true}>
                            {translate("strong")}
                        </TypographyValidation>
                    )}
                </ValidationStatus>
            )}

            {noRules && (
                <ValidationStatus valid={true}>
                    {translate("All characters are allowed")}
                </ValidationStatus>
            )}

            {strength !== Strength.strong && (
                <>
                    {!noRules && typeof config.passwordValidations?.validations === "object" && Object.entries(config.passwordValidations.validations).map(([validation, validator]) => {
                        return (
                            <ValidationStatus valid={!validations[validation]} key={`key_${validation}_${!!validations[validation]}`} >
                                <TypographyValidation
                                    variant={"body2"}
                                    valid={!validations[validation]}
                                >
                                    {translate(validator.description)}
                                </TypographyValidation>
                            </ValidationStatus>
                        );
                    })}

                    {config?.passwordValidations?.defaultRestrictions && (
                        <ValidationStatus valid={!nameInValid}>
                            <TypographyValidation
                                variant={"body2"}
                                valid={!nameInValid}
                            >
                                {translate("Can't contain first name or last name")}
                            </TypographyValidation>
                        </ValidationStatus>
                    )}
                </>
            )}
        </PwdValidation>
    );
};

export default PasswordValidation;
