import { forwardRef, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Container, StyledFormLabel } from "./CommonStyles";
import { DatePicker } from "@kaltura/ds-react-components";
import dayjs, { Dayjs } from "dayjs";

export interface DateFieldProps {
    id: string;
    label: string;
    description?: string;
    /**
     * short-date representation of selected date, according to given dateFormat
     */
    value?: string;
    dateFormat?: string;
}

/**
 * date field for edit entry form
 */
const DateField = forwardRef<any, DateFieldProps>(({
    id, label, description, value, dateFormat =  "DD/MM/YYYY"
                                                           }, ref) => {
    const { control } = useFormContext();
    const inputProps = {
        id:id,
        classes: {root:"chromatic-ignore"}
    }

    const convertValueToDayjs = useCallback((value:string) => {
        // on server we save unix timestamp, but we need to send short-date-format
        // DatePicker component takes dayjs format
        return dayjs(value, dateFormat);
    }, [dateFormat]);

    const handleChange = ((formOnChange: (...event: any[]) => void) => {
        return (dayjsValue: Dayjs | null) => {
            if (!dayjsValue) {
                return;
            }
            // put updated value on form model
            const newval = dayjsValue.format(dateFormat);
            formOnChange(newval);
        }
    })


    return (
        <Container>
            <StyledFormLabel
                htmlFor={id}
                text={label}
                description={description}
            />
            <Controller
                control={control}
                name={id as 'dummy'}
                render={({
                             field: { onChange, onBlur, value, name, ref },
                             fieldState: { isTouched, isDirty, error },
                             formState,
                         }) => (
                    <DatePicker
                        format={dateFormat}
                        inputProps={{...inputProps, onBlur: onBlur}}
                        value={ value ? convertValueToDayjs(value) : null }
                        inputRef={ref}
                        onChange={handleChange(onChange)}
                    />
                )}
            />
        </Container>
    );
});

export default DateField;
