import {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useImperativeHandle,
    forwardRef,
    ForwardedRef,
    useRef
} from "react";
import {KalturaClient, KalturaMultiRequest} from "kaltura-typescript-client";
import { UploadTokenAddAction } from "kaltura-typescript-client/api/types/UploadTokenAddAction";
import { UploadTokenUploadAction } from "kaltura-typescript-client/api/types/UploadTokenUploadAction";
import { KalturaUploadToken } from "kaltura-typescript-client/api/types/KalturaUploadToken";
import { UploadTokenListAction } from "kaltura-typescript-client/api/types/UploadTokenListAction";
import { UploadTokenDeleteAction } from "kaltura-typescript-client/api/types/UploadTokenDeleteAction";
import { KalturaUploadTokenStatus } from "kaltura-typescript-client/api/types/KalturaUploadTokenStatus";
import { KalturaFilterPager } from "kaltura-typescript-client/api/types/KalturaFilterPager";
import { KalturaUploadTokenFilter } from "kaltura-typescript-client/api/types/KalturaUploadTokenFilter";
import {
    numberOfMaxChunksForSingleFile, UploadFileInfo,
    UploadProps, UploadRequest,
    UploadServiceHandler
} from "./UploadServiceProps";

/**
 * Upload Service as a facade to UploadToken API, can uploads single file at a time
 * @param UploadProps
 *
 *
 */
const UploadService = forwardRef<UploadServiceHandler, UploadProps>(
    (
        {
            onUploadProgress,
            onUploadEnded,
            onBeforeUploadStarted,
            onUploadsCanceled,
            onUploadError,
            onCancelError,
            fileInfo,
            serviceUrl,
            ks,
            chunkSize,
            maxConcurrentUploadConnections
        },
        ref: ForwardedRef<UploadServiceHandler>
    ) => {
        const [isLastChunk, setIsLastChunk] = useState(false);
        const currentUploadRequest = useRef<UploadRequest>();

        const _chunkSize = useMemo(() => {
            return chunkSize;
        }, [chunkSize]);

        const _ks = useMemo(() => {
            return ks;
        }, [ks]);

        const _serviceUrl = useMemo(() => {
            return serviceUrl;
        }, [serviceUrl]);

        const currentClient = useMemo(() => {
            return new KalturaClient(
                {
                    endpointUrl: _serviceUrl,
                    clientTag: "kms_client",
                    maxConcurrentUploadConnections: maxConcurrentUploadConnections || 6,
                },
                {
                    ks: _ks
                }
            );
        }, [_ks, _serviceUrl, maxConcurrentUploadConnections]);

        // subject to current definitions:
        // each chunk min 5MB except last chunk;
        // need to calc chunk size based on file size:
        // if(file size > 5MB*10000) {
        //   chunk size = file size / 10000;
        // }
        const calcFileChunkSize = useCallback(
            (fileSize: number) => {
                const relativeChunkSize = Math.ceil(
                    fileSize / numberOfMaxChunksForSingleFile
                );
                return relativeChunkSize > _chunkSize
                    ? relativeChunkSize
                    : _chunkSize;
            },
            [_chunkSize]
        );

        const getAllUserRelatedTokens = useCallback(async () => {
            const tokensToRetrieve: KalturaUploadToken[] = [];
            const listResponse = await currentClient.request(
                new UploadTokenListAction({
                    filter: new KalturaUploadTokenFilter({
                        statusIn: `${KalturaUploadTokenStatus.pending}, ${KalturaUploadTokenStatus.partialUpload}`
                    }),
                    pager: new KalturaFilterPager({
                        pageSize: 100
                    })
                })
            );

            listResponse?.objects.forEach(
                (tokenToRetrieve: KalturaUploadToken) => {
                    tokensToRetrieve.push(tokenToRetrieve);
                }
            );
            return tokensToRetrieve;
        }, [currentClient]);

        const updateChunkSize = useCallback(
            (fileSize: number) => {
                const relevantChunkSize = calcFileChunkSize(fileSize);

                currentClient.setOptions({
                    endpointUrl: _serviceUrl,
                    clientTag: "kms_client",
                    chunkFileSize: relevantChunkSize
                });
            },
            [calcFileChunkSize, currentClient, _serviceUrl]
        );

        const createTokenId = useCallback(
            async (fileName: string) => {
                const tokenData = new KalturaUploadToken();
                tokenData.fileName = fileName;
                const token = await currentClient.request(
                    new UploadTokenAddAction({ uploadToken: tokenData })
                );
                return token?.id;
            },
            [currentClient]
        );

        const cancelUploads = useCallback(
            (tokenIds: string[]) => {
                console.log("cancel requested for token: " + tokenIds[0]);
                // delete upload tokens
                const deleteRequests: KalturaMultiRequest =
                    new KalturaMultiRequest();
                tokenIds.forEach((tokenId) => {
                    // see if the required file is currently uploading
                    // if so, stop the upload
                    if (!!(currentUploadRequest.current) && tokenId === currentUploadRequest.current.relatedUploadToken) {
                        currentUploadRequest.current.requestObject.cancel();
                        currentUploadRequest.current = undefined;
                    }
                    const deleteAction = new UploadTokenDeleteAction({
                        uploadTokenId: tokenId
                    });
                    deleteRequests.requests.push(deleteAction);
                });
                currentClient.multiRequest(deleteRequests).then(
                    (data) => {
                        if (data) {
                            if (data?.hasErrors()) {
                                onCancelError && onCancelError(tokenIds, data.getFirstError().message);
                            }
                            else {
                                console.log(" tokens deleted");
                                onUploadsCanceled && onUploadsCanceled(tokenIds);
                            }
                        } else {
                            onCancelError && onCancelError(tokenIds, "no data returned from delete token");
                        }
                    },
                    ({ message }) => {
                        onCancelError && onCancelError(tokenIds, "delete token multirequest failed");
                    }
                );
            },
            [currentClient, currentUploadRequest, onCancelError, onUploadsCanceled]
        );

        const uploadCurrentFile = useCallback(
            (fileInfo: UploadFileInfo) => {
                const req = currentClient.request(
                    new UploadTokenUploadAction({
                        fileData: fileInfo.file,
                        uploadTokenId: fileInfo?.fileMetaData.tokenId,
                        finalChunk: isLastChunk
                    }).setProgress((loaded: number, total: number) => {
                        const isLastChunk =
                            total >= loaded;
                        if (isLastChunk) {
                            setIsLastChunk(true);
                        }

                        if (onUploadProgress) {
                            onUploadProgress(
                                fileInfo?.fileMetaData.tokenId,
                                Math.round((loaded / total) * 100)
                            );
                        }
                    })
                );
                req.then(
                    (token: KalturaUploadToken | null) => {
                        if (token?.id && onUploadEnded) {
                            onUploadEnded(token?.id);
                        }
                    },
                    (e: Error) => {
                        onUploadError &&
                            onUploadError(
                                fileInfo?.fileMetaData.tokenId,
                                e.message
                            );
                    }
                );
                currentUploadRequest.current = {requestObject: req, relatedUploadToken: fileInfo?.fileMetaData.tokenId};
            },
            [
                currentClient,
                isLastChunk,
                onUploadEnded,
                onUploadProgress,
                onUploadError
            ]
        );


        useEffect(() => {
            if (fileInfo && fileInfo.file.size) {
                // notify
                if (onBeforeUploadStarted) {
                    onBeforeUploadStarted(fileInfo);
                }
                // set client's chunk-size based on given file size
                updateChunkSize(fileInfo.file.size);
                // start upload
                uploadCurrentFile(fileInfo);
            }
        }, [fileInfo]);

        useImperativeHandle(
            ref,
            // We tell React to expose the return value
            // to the parent component using ref:
            () => ({
                getAllUserRelatedTokens: getAllUserRelatedTokens,
                updateChunkSize: updateChunkSize,
                createTokenId: createTokenId,
                cancelUploads: cancelUploads
            }),
            []
        );

        return <></>;
    }
);

export default UploadService;
