import { KmsTypeFilterBase } from "./KmsTypeFilterBase";
import { KmsTypeFilterItemCheckbox, KmsTypeFilterItemGroup, KmsTypeFilterItemRange } from "./item";

/**
 * ts implementation of Kms_Type_Esearch_Filter_Checkbox
 */

export interface KmsTypeFilterCheckbox extends KmsTypeFilterBase<KmsTypeFilterItemCheckbox|KmsTypeFilterItemGroup|KmsTypeFilterItemRange> {
    multiSelection: true;
}

// noinspection PointlessBooleanExpressionJS
export const isKmsTypeFilterCheckbox = (value: KmsTypeFilterBase<any>): value is KmsTypeFilterCheckbox =>
    (value as KmsTypeFilterCheckbox).multiSelection === true;
