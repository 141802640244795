import styled from "@emotion/styled";
import Collapse from "@mui/material/Collapse";
import Filters from "../filters/Filters";
import {Modal, ModalTitle, ModalContent, Typography} from "@kaltura/ds-react-components";
import { translate, useMediaQuery } from "@mediaspace/shared/utils";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {FilterItem} from "@mediaspace/features/channels-collections";
import {useTheme} from "@mediaspace/shared/styled";

export interface FiltersSliderProps {
    isFilterOpen: boolean;
    filters: FilterItem[];
    onFilterUpdate: (value: string) => void;
    onClose: () => void;
    onClearAll: () => void;
}

const StyledTitle = styled(Typography)({
    textTransform: "capitalize"
});

/**
 * copmonent for rendering filters groups, in modal for mobile devices
 * and in a slider for other devices
 */
const FiltersSlider = ({
    isFilterOpen = false,
    filters = [],
    onFilterUpdate,
    onClose,
}: FiltersSliderProps) => {

    const theme = useTheme();

    const isXsScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

    return (
        <>
            {isXsScreen ? (
                <Modal
                    variant="content"
                    open={isFilterOpen}
                >
                    <ModalTitle
                        closeButtonAriaLabel="close"
                        onClose={onClose}
                    >
                        <StyledTitle>
                            {translate("Filters")}
                        </StyledTitle>
                    </ModalTitle>
                    <ModalContent>
                        <Filters
                            filters={filters}
                            onFilterUpdate={onFilterUpdate}
                            isModal={isXsScreen}
                        />
                    </ModalContent>
                </Modal>
            ) : (
                <Collapse
                    in={isFilterOpen}
                    collapsedSize={0}
                >
                    <Filters
                        filters={filters}
                        onFilterUpdate={onFilterUpdate}
                        isModal={isXsScreen}
                    />
                </Collapse>
            )}
        </>
    );
};

export default FiltersSlider;
