import { PropsWithChildren, useContext } from "react";
import { ConfigContext } from "@mediaspace/shared/data-kms-config";
import {
    sidebarButtonsSize,
    useIsMobileSidebarLayout,
    useSidebarLayoutContext
} from "@kaltura/ds-react-layouts";
import { styled } from "@mui/material";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { headerHeight } from "@mediaspace/header-menu";
import { Box, useTheme } from "@mediaspace/shared/styled";

export enum HeroLayoutMode {
    // Take the whole width and height of the page (excluding the header and the sidebar if present)
    // Leave a fixed height of `marginBottom` in the bottom part of the page if specified
    FullSize,
    // Render the contents in a rectangle with player's aspect ratio (16:9)
    AspectRatio,
}

export interface HeroLayoutProps {
    mode: HeroLayoutMode;
    /**
     * How much spacing should be left from the bottom side?
     * (applicable only for the full screen mode)
     *
     * The value is a string with CSS units, e.g. "50px" or "20vh".
     * Note: "%" is not supported as a unit, please use "vh" instead.
     */
    marginBottom?: string;
}

const StyledOuterWrapper = styled(Box, {
    shouldForwardProp(propName) {
        return propName !== "mode";
    }
})(({mode}: { mode: HeroLayoutMode }) => [
    {
        position: "relative",
        width: "100%",
    },
    mode === HeroLayoutMode.FullSize && {
        display: "flex",
        flexDirection: "column",
    },
    mode === HeroLayoutMode.AspectRatio && {
        height: 0,
        paddingBottom: "56.25%",
    },
]);
const StyledInnerWrapper = styled(Box)({
    position: "absolute",
    inset: 0,
});

/**
 * Renders container for a "hero" component.
 * See HeroLayoutMode items for details.
 */
export const HeroLayout = ({ mode, marginBottom = "0px", children }: PropsWithChildren<HeroLayoutProps>) => {
    const configContext = useContext(ConfigContext);
    const headerColor = configContext.dsLayout?.headerColor;
    const topSpacing = headerColor && headerColor !== "transparent" ? headerHeight : 0;

    const { isEnabled } = useSidebarLayoutContext();
    const isMobileSidebarLayout = useIsMobileSidebarLayout();
    const bottomSpacing = isEnabled && isMobileSidebarLayout ? sidebarButtonsSize : 0;

    const defaultStyle = {
        minHeight: mode === HeroLayoutMode.FullSize ? `calc(100vh - ${topSpacing + bottomSpacing}px - ${marginBottom})` : undefined
    };

    const theme = useTheme();

    // inside KAF the hero container has a border radius
    const style = configContext.application?.isKAF ?
        {
            borderRadius: theme.kaltura.shape.roundness2,
            overflow: "hidden"
        } : {};

    return <StyledOuterWrapper
        className="kms-ds-hero-layout"
        mode={mode}
        style={{...defaultStyle, ...style}}
    >
        <StyledInnerWrapper>
            {children}
        </StyledInnerWrapper>
    </StyledOuterWrapper>;
};
