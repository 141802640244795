export function composeClasses<ClassKey extends string>(
    slots: Record<ClassKey, ReadonlyArray<string | false | undefined | null>>,
    getUtilityClass: (slot: string) => string,
    classes?: Record<string, string> | undefined
): Record<ClassKey, string> {
    const output = {} as Record<ClassKey, string>;

    Object.keys(slots).forEach(
        // `Objet.keys(slots)` can't be wider than `T` because we infer `T` from `slots`.
        (slot) => {
            output[slot as ClassKey] = slots[slot as ClassKey]
                .reduce((acc, key) => {
                    if (key) {
                        if (classes && classes[key]) {
                            acc.push(classes[key]);
                        }
                        acc.push(getUtilityClass(key));
                    }
                    return acc;
                }, [] as string[])
                .join(" ");
        }
    );

    return output;
}
