import styled from "@emotion/styled";
import { MouseEvent, useState } from "react";
import { Alert, MenuItem, menuItemClasses, Typography } from "@kaltura/ds-react-components";
import { Check16Icon, Globe24Icon, Link24Icon, Lock24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@mediaspace/shared/utils";
// eslint-disable-next-line @nx/enforce-module-boundaries
import ReactHtmlParser from "@mediaspace/shared/ui/html-react-parser";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { PublishStatus } from "@mediaspace/shared/types";
import { ModalManager } from "./modals/ModalManager";
import { PublishSaveResponseData } from "@mediaspace/shared/types/publish/PublishSaveResponseData";
import { ActionsMenuItemProps } from "@mediaspace/shared/media-actions";

export interface PublishMenuItemsProps extends ActionsMenuItemProps {
    entryIds: string[];
    publishStatus: PublishStatus;
    enableUnlisted?: boolean;
    crossApplicationUnpublishing?: boolean;
    allowUnpublishPublished?: boolean;
    allowPublish?: boolean;
    warningMessages?: string[];
    onCloseMenu?: () => void;
    onItemStateChange?: (key: string, value: any) => void;
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    height: "auto",
    maxWidth: 300,
    "& > div": {
        height: "auto",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    [`& .${menuItemClasses.title}`]: {
        display: "flex",
    },
}));

const StyledAlertMenuItem = styled(MenuItem)(({ theme }) => ({
    height: "auto",
    maxWidth: 300,
    marginTop: theme.spacing(1),
    "&:focus, &:focus > div, &:hover, &:hover > div": {
        backgroundColor: "transparent",
    },
    "& > div": {
        height: "auto",
    },
}));

const StyledIconWrapper = styled("span")(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledLabelWrapper = styled("span")({
    display: "flex",
    flexDirection: "column",
    maxWidth: 236,
    textWrap: "wrap",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
    whiteSpace: "break-spaces",
    cursor: "default",
    "& a": {
        color: theme.kaltura.palette.primary.main,
        cursor: "pointer",
    },
}));

/**
 * Publish indication menu items
 *
 * meant to be used in menu: <Menu><PublishMenuItems /></Menu> (my media, upload)
 * or in nested menu items:  <Menu><NestedMenuItem><PublishMenuItems /></NestedMenuItem></Menu> (media page)
 * and so - the top level component MUST be a fragment <></>
 */
export function PublishMenuItems(props: PublishMenuItemsProps) {
    const {
        entryIds,
        publishStatus,
        enableUnlisted = true,
        crossApplicationUnpublishing = false,
        allowUnpublishPublished = true,
        allowPublish = true,
        warningMessages,
        onCloseMenu,
        onItemStateChange,
    } = props;

    const [open, setOpen] = useState(false);
    const [targetStatus, setTargetStatus] = useState<PublishStatus>();

    const sendButtonAnalytics = useButtonAnalytics();

    const isPrivate = publishStatus === PublishStatus.PRIVATE;
    const isUnlisted = publishStatus === PublishStatus.UNLISTED;
    const isPublished = publishStatus === PublishStatus.PUBLISHED;

    const entryId = entryIds.length === 1 ? entryIds[0] : undefined;

    const handleSubmit = (result: PublishSaveResponseData) => {
        // update state
        onItemStateChange?.("publishStatus", result.publishStatus);
        onItemStateChange?.("warningMessages", result.warningMessages);
        // close modal
        setOpen(false);
        // close menu
        onCloseMenu?.();
    };

    const handleClose = () => {
        // close modal
        setOpen(false);
    };

    const handlePrivateClick = () => {
        isPrivate && onCloseMenu?.();
        setOpen(!isPrivate);
        setTargetStatus(PublishStatus.PRIVATE);
        !isPrivate && sendButtonAnalytics("Publishing indicator menu - Private", ButtonClickAnalyticsType.CHOOSE);
    };

    const handleUnlistedClick = () => {
        isUnlisted && onCloseMenu?.();
        setOpen(!isUnlisted);
        setTargetStatus(PublishStatus.UNLISTED);
        !isUnlisted && sendButtonAnalytics("Publishing indicator menu - Unlisted", ButtonClickAnalyticsType.CHOOSE);
    };

    const handlePublishClick = () => {
        setOpen(true);
        setTargetStatus(PublishStatus.PUBLISHED);
        isPublished
            ? sendButtonAnalytics(
                  "Publishing indicator menu - Update publish locations",
                  ButtonClickAnalyticsType.CHOOSE
              )
            : sendButtonAnalytics("Publishing indicator menu - Publish", ButtonClickAnalyticsType.CHOOSE);
    };

    const handleWarningClick = (event: MouseEvent<HTMLElement>) => {
        // beacon text supplied by legacy kms publish code
        const beacon = (event.target as HTMLElement).dataset?.beacon;
        if (beacon) {
            sendButtonAnalytics(
                beacon,
                ButtonClickAnalyticsType.EDIT,
                entryId
            );
        }
    };

    return (
        <>
            <StyledMenuItem
                key="private"
                className="kms-ds-publish-menu-item-private"
                onClick={handlePrivateClick}
                autoFocus={isPrivate}
                icon={isPrivate ? <Check16Icon /> : undefined}
                aria-current={isPrivate}
                disabled={!allowUnpublishPublished && isPublished}
            >
                <StyledIconWrapper>
                    <Lock24Icon />
                </StyledIconWrapper>
                <StyledLabelWrapper>
                    {translate("Private")}
                    <StyledTypography variant="body2">{translate("Only I can view")}</StyledTypography>
                </StyledLabelWrapper>
            </StyledMenuItem>
            {enableUnlisted && (
                <StyledMenuItem
                    key="unlisted"
                    className="kms-ds-publish-menu-item-unlisted"
                    onClick={handleUnlistedClick}
                    autoFocus={isUnlisted}
                    icon={isUnlisted ? <Check16Icon /> : undefined}
                    aria-current={isUnlisted}
                    disabled={!allowUnpublishPublished && isPublished}
                >
                    <StyledIconWrapper>
                        <Link24Icon />
                    </StyledIconWrapper>
                    <StyledLabelWrapper>
                        {translate("Unlisted")}
                        <StyledTypography variant="body2">
                            {translate("Anyone with the link can view")}
                        </StyledTypography>
                    </StyledLabelWrapper>
                </StyledMenuItem>
            )}
            <StyledMenuItem
                key="publish"
                className="kms-ds-publish-menu-item-publish"
                onClick={handlePublishClick}
                autoFocus={isPublished}
                icon={isPublished ? <Check16Icon /> : undefined}
                aria-current={isPublished}
                disabled={!allowPublish}
            >
                <StyledIconWrapper>
                    <Globe24Icon />
                </StyledIconWrapper>
                <StyledLabelWrapper>
                    {isPublished ? translate("Change publish locations") : translate("Publish")}
                    <StyledTypography variant="body2">
                        {translate("Anyone with entitlements to the gallery/channel can view")}
                    </StyledTypography>
                </StyledLabelWrapper>
            </StyledMenuItem>
            {warningMessages &&
                warningMessages.map((warningMessage, index) => (
                    <StyledAlertMenuItem key={`warn_${index}`} onClick={handleWarningClick}>
                        <StyledAlert severity="warning" icon={false}>
                            {ReactHtmlParser(warningMessage)}
                        </StyledAlert>
                    </StyledAlertMenuItem>
                ))}

            {/* mount modals to force data fetch */}
            {open && !!targetStatus && (
                <ModalManager
                    entryIds={entryIds}
                    crossApplicationUnpublishing={crossApplicationUnpublishing}
                    publishStatus={publishStatus}
                    targetStatus={targetStatus}
                    onSubmit={handleSubmit}
                    onClose={handleClose}
                />
            )}
        </>
    );
}

export default PublishMenuItems;
