import { useState } from "react";
import {
    Menu,
    MenuProps,
    menuClasses,
    MenuItem,
} from "@kaltura/ds-react-components";
import { alpha } from "@mediaspace/shared/styled";
import styled from "@emotion/styled";
import {
    KmsTypeAddNewMenuItem,
    KmsTypeAddNewMenuSection,
} from "@mediaspace/shared/types/addNew";
import { AddNewIllustration } from "./AddNewIllustration";
import { translate, useMediaQuery } from "@mediaspace/shared/utils";
import { isMobile } from "react-device-detect";
import {useButtonAnalytics} from "@mediaspace/hooks";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export interface AddNewMenuProps extends MenuProps {
    sections: KmsTypeAddNewMenuSection[];
    hideIllustration?: boolean;
    /**
     * set menu minHeight to auto so menu height will fit menu items content - default is 400px
     * used in KAF
     */
    autoMinHeight?: boolean;
    /**
     * set menu item left and right margins to 8px - default is 16px
     * used in KAF
     */
    narrowMargin?: boolean;
}

const StyledMenu = styled(Menu, {
    shouldForwardProp(propName: string) {
        return propName !== "shortScreen" && propName !== "veryShortScreen" && propName !== "hideIllustration" && propName !== "autoMinHeight";
    }
})<{
    shortScreen: boolean;
    veryShortScreen: boolean;
    hideIllustration?: boolean;
    autoMinHeight?: boolean;
}>(
    ({
        theme,
        shortScreen,
        veryShortScreen,
        hideIllustration,
        autoMinHeight
    }) => ({
        [`.${menuClasses.paper}`]: {
            // ovveride `backgroundColor` due to dark-mode design
            backgroundColor: theme.kaltura.palette.surfaces.background,
            borderRadius: theme.kaltura.shape.roundness2,
            minWidth: hideIllustration ? 216 : 490,
            maxHeight: shortScreen ? "100vh" : 562,
            minHeight: autoMinHeight ? "auto" : (veryShortScreen ? "100vh" : 400),
            // menu is vertically aligned when menu items reach bottom screen edge by MUI
            // in KMS the menu is rendered at the top of the page header thus 20px are added to align with it
            // in KAF (autoMinHeight=true) the menu is rendered at the bottom of my-media header thus it is not needed
            marginTop: shortScreen && !autoMinHeight ? 20 : "",
            [theme.breakpoints.down("md")]: {
                minWidth: 216,
            },
        },
    })
);

const StyledMenuItem = styled(MenuItem<"a">, {
    shouldForwardProp(propName) {
        return propName !== "narrowMargin";
    },
})<{ narrowMargin?: boolean }>(({ theme, narrowMargin }) => ({
    height: 48,
    marginLeft: theme.spacing(narrowMargin ? 1 : 2),
    marginRight: theme.spacing(narrowMargin ? 1 : 2),
    marginTop: 0,
    marginBottom: 0,
    maxWidth: 213,
    [`& > div`]: {
        height: 48,
        [`& > div`]: {
            fontWeight: theme.kaltura.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(16),
            textTransform: "capitalize",
            color: theme.kaltura.palette.tone1,
            textDecoration: "none",
            verticalAlign: "middle",
            "&:hover": {
                color: theme.kaltura.palette.tone1,
                fontWeight: theme.kaltura.typography.fontWeightRegular,
            },
            "&:visited": {
                color: theme.kaltura.palette.tone1,
            },
        },
    },
    [`&:hover > div, &:focus > div`]: {
        backgroundColor: alpha(theme.kaltura.palette.primary.main, 0.05),
        borderRadius: theme.kaltura.shape.roundness1,
    },
    // override bootstrap a:focus textDecoration loaded on non-DS pages (KAF my-media)
    // override kms a:focus outline loaded on non-DS pages (KAF my-media)
    "&:focus": {
        textDecoration: "none",
        outline: "none"
    },
    [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

const StyledIcon = styled("img")(({ theme }) => ({
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
}));

const StyledSectionTitle = styled("li")(({ theme }) => ({
    margin: theme.spacing(2, 3, 1, 3),
    maxWidth: "213px",
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(13),
    color: theme.kaltura.palette.tone3,
    overflow: "hidden",
    textOverflow: "ellipsis",
}));

/**
 * `Add New` menu
 */
export function AddNewMenu(props: AddNewMenuProps) {
    const {
        sections,
        hideIllustration,
        autoMinHeight = false,
        narrowMargin = false,
        ...rest
    } = props;
    const [currentItem, setCurrentItem] = useState(sections[0].items[0]);

    const shortScreen = useMediaQuery(`(max-height:562px)`);
    const veryShortScreen = useMediaQuery(`(max-height:400px)`);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleMenuItemClick = (item: KmsTypeAddNewMenuItem) => {
        if (item.analyticsValue) {
            sendButtonAnalytics(`Add - ${item.analyticsValue}`, ButtonClickAnalyticsType.CHOOSE);
        }
    }

    // flatten section label and items to items array
    const menItems = sections.map(
        (section: KmsTypeAddNewMenuSection, sectionIndex: number) => {
            // add section items
            const items = section.items
                .filter((item) => !isMobile || !item.hideOnMobile)
                .map((item: KmsTypeAddNewMenuItem, index: number) => {
                    return (
                        (!isMobile || !item.hideOnMobile) && (
                            <StyledMenuItem
                                key={sectionIndex + "-" + index}
                                onFocus={() => setCurrentItem(item)}
                                onMouseEnter={() => setCurrentItem(item)}
                                component="a"
                                href={item.url}
                                aria-label={translate("%1 menuitem %2 %3",[item.label, item.description, section?.label])}
                                onClick={() => {
                                    handleMenuItemClick(item);
                                }}
                                narrowMargin={narrowMargin}
                            >
                                <StyledIcon src={item.iconUrl} />
                                    {item.label}
                            </StyledMenuItem>
                        )
                    );
                });

            // add section label if we have one
            const label =
                items.length > 0 && section.label ? (
                    <StyledSectionTitle role="presentation">
                        {translate(section.label)}
                    </StyledSectionTitle>
                ) : (
                    []
                );

            return [label, items];
        }
    );

    return (
        <StyledMenu
            shortScreen={shortScreen}
            veryShortScreen={veryShortScreen}
            hideIllustration={hideIllustration}
            autoMinHeight={autoMinHeight}
            {...rest}
        >
            {menItems}
            {!hideIllustration && <AddNewIllustration item={currentItem}></AddNewIllustration>}
        </StyledMenu>
    );
}

export default AddNewMenu;
