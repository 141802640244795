import { useState, useContext, useEffect } from "react";
import { KmsTypePublishEntryCategories, KmsTypePublishUserCategories } from "@mediaspace/shared/types";
import { Tabs, Tab } from "@kaltura/ds-react-components";
// eslint-disable-next-line @nx/enforce-module-boundaries
import TabPanel from "@mediaspace/shared/ui/tabs/TabPanel";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { tabProps } from "@mediaspace/shared/ui/tabs/helper";
import { translate } from "@mediaspace/shared/utils";
import { ConfigContext } from "@mediaspace/shared/data-kms-config";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { ChannelsTab } from "./channels-tab/ChannelsTab";
import { GalleriesTab } from "./galleries-tab/GalleriesTab";
import { ExceptionsTab } from "./exceptions-tab/ExceptionsTab";
import { ExternalTab } from "./external-tab/ExternalTab";

export interface PublishTabsProps {
    entryCategories: KmsTypePublishEntryCategories;
    userCategories: KmsTypePublishUserCategories;
    entryId: string;
    crossApplicationUnpublishing: boolean;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    onUnpublishExternal: () => void;
}

enum TabsValue {
    channels = "channels",
    galleries = "galleries",
    exceptions = "exceptions",
    external = "external",
}

/**
 * Publish modal tabs
 */
export function PublishTabs(props: PublishTabsProps) {
    const {
        entryCategories,
        userCategories,
        entryId,
        crossApplicationUnpublishing,
        onCategoryChange,
        onUnpublishExternal,
    } = props;

    const { channels = {}, galleries = {}, externalCategories = [] } = entryCategories;
    const { myChannels, moreChannels, galleries: userGalleries } = userCategories;

    const userChannels = myChannels || moreChannels;
    const [tabValue, setTabValue] = useState(userChannels ? TabsValue.channels : TabsValue.galleries);

    const configContext = useContext(ConfigContext);
    const isKaf = configContext.application?.isKAF;

    const hasPendingRejected =
        !!channels?.pending?.length ||
        !!galleries?.pending?.length ||
        !!channels?.rejected?.length ||
        !!galleries?.rejected?.length;

    const hasExternalCategories = externalCategories.length > 0;

    const sendButtonAnalytics = useButtonAnalytics();

    // send analytics for default tab (channels)
    useEffect(() => {
        sendButtonAnalytics(
            `Publish - Set publishing settings modal box - ${TabsValue.channels} tab`,
            ButtonClickAnalyticsType.BROWSE,
            entryId
        );
    }, []);

    // send analytics for tab change
    const handleTabChange = (event: React.SyntheticEvent, newValue: TabsValue) => {
        setTabValue(newValue);

        sendButtonAnalytics(
            `Publish - Set publishing settings modal box - ${newValue} tab`,
            ButtonClickAnalyticsType.BROWSE,
            entryId
        );
    };

    const handlePendingClick = () => {
        setTabValue(TabsValue.exceptions);
    };

    return (
        <>
            <Tabs
                className="kms-ds-publish-locations-tabs"
                value={tabValue}
                onChange={handleTabChange}
                aria-label={translate("Publishing locations")}
            >
                {userChannels && (
                    <Tab key="tab-channels" label={translate("Channels")} {...tabProps(TabsValue.channels)} />
                )}
                {!isKaf && userGalleries && (
                    <Tab key="tab-galleries" label={translate("Galleries")} {...tabProps(TabsValue.galleries)} />
                )}
                {hasPendingRejected && (
                    <Tab key="tab-exceptions" label={translate("Exceptions")} {...tabProps(TabsValue.exceptions)} />
                )}
                {hasExternalCategories && (
                    <Tab key="tab-external" label={translate("External")} {...tabProps(TabsValue.external)} />
                )}
            </Tabs>
            {userChannels && (
                <TabPanel key="panel-channels" currentTab={tabValue} name={TabsValue.channels}>
                    <ChannelsTab
                        entryId={entryId}
                        channels={channels}
                        userCategories={userCategories}
                        onCategoryChange={onCategoryChange}
                        onPendingClick={handlePendingClick}
                    />
                </TabPanel>
            )}
            {!isKaf && userGalleries && (
                <TabPanel key="panel-galleries" currentTab={tabValue} name={TabsValue.galleries}>
                    <GalleriesTab
                        entryId={entryId}
                        galleries={galleries}
                        userCategories={userCategories}
                        onCategoryChange={onCategoryChange}
                        onPendingClick={handlePendingClick}
                    />
                </TabPanel>
            )}
            {hasPendingRejected && (
                <TabPanel key="panel-exceptions" currentTab={tabValue} name={TabsValue.exceptions}>
                    <ExceptionsTab
                        entryId={entryId}
                        channels={channels}
                        galleries={galleries}
                        onCategoryChange={onCategoryChange}
                    />
                </TabPanel>
            )}
            {hasExternalCategories && (
                <TabPanel key="panel-external" currentTab={tabValue} name={TabsValue.external}>
                    <ExternalTab
                        externalCategories={externalCategories}
                        crossApplicationUnpublishing={crossApplicationUnpublishing}
                        onUnpublishExternal={onUnpublishExternal}
                    />
                </TabPanel>
            )}
        </>
    );
}
