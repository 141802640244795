import { Config } from "@mediaspace/shared/data-kms-config";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HeaderMenu, HeaderMenuProps } from "@mediaspace/header-menu";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Footer } from "@mediaspace/footer";
import { SidebarContentsTitle, SidebarMenuButton, SidebarMenuButtonProps } from "@kaltura/ds-react-layouts";
import { Chat24Icon } from "@kaltura/ds-react-icons";
import { Box } from "@mediaspace/shared/styled";
// eslint-disable-next-line @nx/enforce-module-boundaries
import NavigationPanel from "@mediaspace/header-menu/SidebarMenu/NavigationPanel";
import { mockContext } from "@mediaspace-react-components/storybook";

interface MockDsLayoutConfigOptions {
    headerColor?: "dark" | "light" | "transparent";
    headerProps?: HeaderMenuProps;
}

const MockSidebarContents = () => <div>
    <SidebarContentsTitle title={"Private chat"} icon={<Chat24Icon />} />
    <Box p={2}>Shhh! It's private!</Box>
</div>;

const sidebarMenuButtonProps: SidebarMenuButtonProps =  {
    title: "Private chat",
    icon: <Chat24Icon />,
};

/**
 * DS layout config for storybook.
 *
 * Usage:
 * ```
 * <DsLayout
 *     context={getMockDsLayoutConfig()}
 *     component={() => <>...</>}
 * />
 * ```
 */
export const getMockDsLayoutConfig = ({ headerColor, headerProps }: MockDsLayoutConfigOptions): Config => ({
    ...mockContext,
    dsLayout: {
        components: {
            header: (headerColor || headerProps) ? [{
                name: "Header",
                component: HeaderMenu,
                props: headerProps ?? {
                    headerColor,
                    logo: {
                        hasLogo: false,
                    },
                    showNav: true,
                    scrollThreshold: 60,
                },
            }] : [],
            footer: [{
                name: "Footer",
                component: Footer,
                props: {
                    backgroundColor: "#2E2E2E",
                    backgroundUrl: "",
                    textAndLinks: [
                        {
                            sectionText: "Corp Site",
                            sectionType: "URL",
                            sectionLink: "https://corp.kaltura.com"
                        },
                        {
                            sectionText: "Corp Site but in a very very very long text soooooooooooo long its not reasonable, really not",
                            sectionType: "URL",
                            sectionLink: "https://corp.kaltura.com"
                        },
                        {
                            sectionText: "Pricing",
                            sectionType: "URL",
                            sectionLink: "https://corp.kaltura.com/pricing"
                        },
                        {
                            sectionText: "Learning",
                            sectionType: "URL",
                            sectionLink: "https://learning.kaltura.com"
                        },
                        {
                            sectionText: "Script",
                            sectionType: "Script",
                            sectionLink: "alert('hi!!!');"
                        },
                        {
                            sectionText: "Empty Script",
                            sectionType: "Script",
                            sectionLink: ""
                        }
                    ],
                    linkSeparator: "\u2022",
                    bottomText: "Kaltura, Inc. or its affiliates, All Rights Reserved"
                }
            }],
            floated: [{
                name: "NavigationPanel",
                component: NavigationPanel,
                props: {
                    pages: headerProps?.pages ?? [],
                    topLinks: headerProps?.topLinks ?? [],
                }
            }],
        },
        sidebarTopButtons: [{
            buttonDescriptor: {
                id: "cnc",
                buttonComponent: SidebarMenuButton,
                buttonProps: sidebarMenuButtonProps,
                contentsComponent: MockSidebarContents,
            },
            contentsProps: {},
        }],
        headerColor,
    },
});
