import styled from "@emotion/styled";
import { forwardRef, useEffect, useRef, useState } from "react";
import { DatePicker, TimePicker } from "@kaltura/ds-react-components";
import dayjs, { Dayjs } from "dayjs";
import { useEventHandler } from "@mediaspace/hooks";

export const DEFAULT_TIME_FORMAT = "hh:mm a";

export interface DateFieldWithTimeProps {
    id?: string,

    /**
     * current value to show in the picker
     */
    value: Dayjs | null,

    onChange: (value: Dayjs | null) => void,

    onBlur: () => void,

    error?: boolean,

    /**
     * date format to show in the date picker
     */
    dateFormat?: string,

    timeFormat?: string,

    ariaLabelledby?: string
}

const StyledDateField = styled.div(({ theme }) => ({
    display: "flex",
    columnGap: theme.spacing(1),
    // margin: theme.spacing(1, 0),
}));

const DateFieldWithTime = forwardRef<HTMLInputElement, DateFieldWithTimeProps>(({
                                                        id,
                                                        value,
                                                        onChange,
                                                        onBlur,
                                                        error,
                                                        dateFormat,
                                                        timeFormat = DEFAULT_TIME_FORMAT,
                                                        ariaLabelledby}: DateFieldWithTimeProps, ref) => {

    const isMounted = useRef(false);
    const datePickerInputProps = {classes: {root: "chromatic-ignore"}, onBlur: onBlur, "aria-labelledby": ariaLabelledby};

    const [timeValue, setTimeValue] = useState(value);
    const [dateValue, setDateValue] = useState(value);

    const [lastValue, setLastValue] = useState<Date>();

    const onChangeMemoized = useEventHandler(onChange);

    useEffect(() => {
        // avoid firing on initial mount
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }
        // get the date from dateValue
        const newValue = dateValue ? new Date(dateValue.toDate()) : undefined;
        // if we have date and time values
        if (newValue && timeValue) {
            // get the time from timeValue
            const t = timeValue.toDate();
            // add time to date
            newValue.setHours(t.getHours(), t.getMinutes(), 0, 0);
        }
        // only fire for real changes
        if (lastValue !== newValue) {
            setLastValue(newValue);
            onChangeMemoized(newValue ? dayjs(newValue) : null);
        }

    }, [dateValue, timeValue, onChangeMemoized]);


    return (
        <StyledDateField >
            <DatePicker inputProps={datePickerInputProps}
                        inputRef={ref}
                        format={dateFormat}
                        value={dateValue}
                        onChange={(newDate) => {
                            const date = newDate && newDate.isValid() ? newDate : null;
                            setDateValue(date);
                        }}/>
            <TimePicker onChange={(newDate) => {
                const date = newDate && newDate.isValid() ? newDate : null;
                setTimeValue(date);
            }} format={timeFormat} defaultValue={timeValue} ariaLabelledby={ariaLabelledby} />
        </StyledDateField>
    );
});

export default DateFieldWithTime;
