import { Component, PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { Integrations24Icon } from "@kaltura/ds-react-icons";

export interface ErrorBoundaryProps {
    fallback?: React.ReactNode;
}

interface State {
    hasError: boolean;
}

const StyledErrorBoundary = styled("div")(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
}));

/**
 * Error boundary for components that fetch kms data
 */
export class ErrorBoundary extends Component<PropsWithChildren<ErrorBoundaryProps>, State> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, info: any) {
        console.log('ErrorBoundary', error, info);
    }

    render() {
        if (this.state.hasError) {

            if(this.props.fallback){
                return this.props.fallback;
            }

            return (
                <StyledErrorBoundary>
                    <Integrations24Icon />
                </StyledErrorBoundary>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
