import { Config, defaultContext } from "@mediaspace/shared/data-kms-config";

export const rootGalleryCategoryId = "13744992";
export const rootGalleryCategoryFullId = "13744972>13744982>" + rootGalleryCategoryId;

export const mockContext: Config = {
    ...defaultContext,
    categories: {
        rootGalleryCategoryId,
    },
};

export const mockKafContext: Config = {
    ...mockContext,
    application:  {
        ...mockContext.application,
        isKAF: true,
    }
};
