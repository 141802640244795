import FacebookSocialLink from "./FacebookSocialLink";
import InstagramSocialLink from "./InstagramSocialLink";
import LineSocialLink from "./LineSocialLink";
import SlackSocialLink from "./SlackSocialLink";
import TiktokSocialLink from "./TiktokSocialLink";
import TwitchSocialLink from "./TwitchSocialLink";
import TwitterSocialLink from "./TwitterSocialLink";
import YoutubeSocialLink from "./YoutubeSocialLink";
import LinkedinSocialLink from "./LinkedinSocialLink";

export enum Social {
    twitter = "twitter",
    facebook = "facebook",
    linkedin = "linkedin",
    youtube = "youtube",
    instagram = "instagram",
    twitch = "twitch",
    slack = "slack",
    tiktok = "tiktok",
    line = "line",
}
export type SocialType = keyof typeof Social;


export interface SocialLinksProps {
    linkType: SocialType;
}

export function SocialLinks(props: SocialLinksProps) {
    let Component;
    switch (props.linkType) {
        case Social.facebook:
            Component = FacebookSocialLink;
            break;
        case Social.instagram:
            Component = InstagramSocialLink;
            break;
        case Social.line:
            Component = LineSocialLink;
            break;
        case Social.linkedin:
            Component = LinkedinSocialLink;
            break;
        case Social.slack:
            Component = SlackSocialLink;
            break;
        case Social.tiktok:
            Component = TiktokSocialLink;
            break;
        case Social.twitch:
            Component = TwitchSocialLink;
            break;
        case Social.twitter:
            Component = TwitterSocialLink;
            break;
        case Social.youtube:
            Component = YoutubeSocialLink;
            break;
    }
    if (Component) return ( <Component />);
    return null;
}

export default SocialLinks;
