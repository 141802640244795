import { translate } from "@mediaspace/shared/utils";
import {
    Button,
    MenuItem,
    ModalActions,
    ModalContent,
    ModalTitle,
    NestedMenuItem,
    Typography
} from "@kaltura/ds-react-components";
import { ChevronRight16Icon } from "@kaltura/ds-react-icons";
import { SyntheticEvent, useMemo, useState } from "react";
import { StyledContainer, StyledModal } from "./styled-components";
import { Collaborator, HtmlReactParser, UserSelection, useToastsContext } from "@mediaspace/shared/ui";
import MultiSelectHoriz from "@mediaspace/shared/ui/form/multi-select-horiz/MultiSelectHoriz";
import { LabelledValue } from "@mediaspace/shared/types";
import { KmsEnumMediaCollaborationPermissions } from "@mediaspace/shared/types/KmsEnumMediaCollaborationPermissions";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { CustomCollaborationRole } from "@mediaspace/shared/types/collaboration/CustomCollaborationRole";
import { ActionsMenuItemProps } from "@mediaspace/shared/media-actions";


/**
 * expected structure of server response
 */
interface CollaborationResponse {
    /**
     * true if **all** permissions set successfully, false otherwise
     */
    success: boolean;

    /**
     * list of entries where permissions could not be set
     */
    errorEntries?: { id: string, name: string }[];

    /**
     * list of user ids that could not b set as collaborators
     */
    errorUserIds?: string[];
}

export interface SetCollaborationProps extends ActionsMenuItemProps {

    setCollaborators: (action: "add" | "remove", permissions: KmsEnumMediaCollaborationPermissions[], userIds: string[]) => Promise<CollaborationResponse>;

    /**
     * a callback to the wrapper component indicating all is done
     */
    onCollaboratorsSet?: () => void;

    /**
     * KMS action to call when searching for users.
     * the search term will be appended on the url.
     * expected response in the form of {screenName: string, id: string, thumbnailUrl?: string}[]
     */
    usersSearchUrl: string;

    analyticsEventNameBase: string;

    /**
     * close menu on menu item click
     */
    onCloseMenu: () => void;

    /**
     * custom collaboration roles
     */
    customRoles: CustomCollaborationRole[];
}

/**
 * media collaboration setter.
 * shows the main menu item, inner menu and collaboration settings modal
 */
export function SetCollaboration({ setCollaborators, onCollaboratorsSet, usersSearchUrl, analyticsEventNameBase, onCloseMenu, customRoles, autoFocus }: SetCollaborationProps) {

    const [modalType, setModalType] = useState<"add" | "remove" | null>(null);
    const [selectedPermissions, setSelectedPermissions] = useState<LabelledValue[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<Collaborator[]>([]);
    const [submitted, setSubmitted] = useState(false);

    const { showToast } = useToastsContext();
    const sendButtonAnalytics = useButtonAnalytics();

    const permissionsOptions = useMemo(() => {
        const perms = [
            { label: translate("Viewer"), value: KmsEnumMediaCollaborationPermissions.CO_VIEW },
            { label: translate("Publisher"), value: KmsEnumMediaCollaborationPermissions.CO_PUBLISH },
            { label: translate("Editor"), value: KmsEnumMediaCollaborationPermissions.CO_EDIT }
        ];
        if (customRoles.length) {
            for (const customRole of customRoles) {
                for (const item of perms) {
                    if (item.value === customRole.role) {
                        item.label += ` / ${customRole.label}`;
                        break;
                    }
                }
            }
        }
        return perms;
    }, [customRoles]);


    const showCollaborationModal = (action: "add" | "remove") => {
        setModalType(action);
        sendButtonAnalytics(`${analyticsEventNameBase} - ${action} collaborator selected`, ButtonClickAnalyticsType.CHOOSE);
    };

    const handleCancel = () => {
        setModalType(null);
        sendButtonAnalytics(`${analyticsEventNameBase} - ${modalType} collaborator cancelled`, ButtonClickAnalyticsType.CHOOSE);
        onCloseMenu();
    };

    const handleSubmit = () => {

        setSubmitted(true);
        sendButtonAnalytics(`${analyticsEventNameBase} - ${modalType} collaborator saved`, ButtonClickAnalyticsType.CHOOSE);

        const permissions = selectedPermissions.map(item => item.value as KmsEnumMediaCollaborationPermissions);
        const users = selectedUsers.map(user => user.id);


        // Can't get to handleSubmit() when modalType is undefined - the modal will be closed in this case
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setCollaborators(modalType!, permissions, users)
            .then(
                (response) => {
                    if (response.success) {
                        showToast({ severity: "success", message: translate("Media successfully updated") });
                    }
                    else if (response.errorEntries) {
                        let msg = translate("Could not update the following media:");
                        msg += "<ul>";
                        msg += response.errorEntries.map((e) => `<li>${e.id}, ${e.name}</li>`).join("");
                        showToast({ severity: "error", message: HtmlReactParser(msg) });
                    }
                    else {
                        showToast({ severity: "error", message: translate("Error updating media") });
                    }
                },
                (error) => {
                    showToast({ severity: "error", message: translate("Error updating media") });
                })
            .finally(() => {
                // close modal
                setModalType(null);
                // notify
                onCloseMenu();
                onCollaboratorsSet?.();
            });
    };


    let modalTitle = "", modalInstruction = "";
    if (modalType === "add") {
        modalTitle = translate("Add Collaboration");
        modalInstruction = translate("Select users who will have permission to view, publish, and/or edit the media.");
    }
    if (modalType === "remove") {
        modalTitle = translate("Remove Collaboration");
        modalInstruction = translate("Revoke user's permission to view, publish, and/or edit the media.");
    }

    const disableSubmitButton = submitted || !selectedUsers.length || !selectedPermissions.length;

    return (
        <>
            <NestedMenuItem icon={<ChevronRight16Icon />} label={translate("Add/Remove collaborators")} autoFocus={autoFocus}>
                <MenuItem onClick={() => showCollaborationModal("add")}>
                    {translate("Add collaborators")}
                </MenuItem>
                <MenuItem onClick={() => showCollaborationModal("remove")}>
                    {translate("Remove collaborators")}
                </MenuItem>
            </NestedMenuItem>

            <StyledModal classes={{ "paper": "kms-ds-set-collaborators-modal" }} open={!!modalType}>
                <ModalTitle>{modalTitle}</ModalTitle>
                <ModalContent>
                    <StyledContainer>
                        <Typography variant={"body2"}>
                            {modalInstruction}
                        </Typography>
                        <MultiSelectHoriz
                            value={selectedPermissions}
                            onChange={setSelectedPermissions}
                            options={permissionsOptions}
                        />
                        <UserSelection
                            multiple={true}
                            placeholder={translate("Search collaborators")}
                            url={usersSearchUrl}
                            onChange={(event: SyntheticEvent<Element, Event>, value: Collaborator[]) => {
                                setSelectedUsers(value);
                            }}
                        />

                    </StyledContainer>

                </ModalContent>
                <ModalActions>
                    <Button variant={"borderless"} onClick={handleCancel}>{translate("Cancel")}</Button>
                    <Button onClick={handleSubmit}
                            disabled={disableSubmitButton}>
                        {modalType === "add" ? translate("Add") : translate("Update")}
                    </Button>
                </ModalActions>
            </StyledModal>
        </>
    );
}

