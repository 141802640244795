import styled from "@emotion/styled";
import { Skeleton, systemWidth } from "@mediaspace/shared/styled";
import { Carousel } from "../../carousel/Carousel";
import { CarouselItemSkeleton } from "../../carousel/CarouselItem";

export interface SinglePlaylistSkeletonProps {
    fullScreenWidth?: boolean;
}

const StyledTitleSkeleton = styled(Skeleton)(({ theme }) => ({
    height: theme.spacing(3),
    borderRadius: theme.kaltura.shape.roundness1,
}));

const StyledTitleContainer = styled("div", {
    shouldForwardProp(propName) {
        return propName !== "fullScreenWidth";
    },
})<{ fullScreenWidth?: boolean }>(({ theme, fullScreenWidth }) => ({
    "&": !fullScreenWidth && systemWidth({ theme }),
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
}));

const CarouselContainer = styled("div", {
    shouldForwardProp(propName) {
        return propName !== "fullScreenWidth";
    },
})<{ fullScreenWidth?: boolean }>(({ theme, fullScreenWidth }) => ({
    "&": !fullScreenWidth && systemWidth({ theme }),
}));


/**
 * Skeleton for SinglePlaylist component
 */
export function SinglePlaylistSkeleton({ fullScreenWidth = false }: SinglePlaylistSkeletonProps) {

    return (
        <div className="single-playlist-skeleton single-playlist kms-ds-homepage-component-single-playlist">

            <StyledTitleContainer
                fullScreenWidth={fullScreenWidth}
                className={"kms-ds-single-playlist-title kms-ds-single-playlist-title-skeleton"}
            >
                <StyledTitleSkeleton variant={"rectangular"} width={220} />
                <StyledTitleSkeleton variant={"rectangular"} width={100} />
            </StyledTitleContainer>
            <CarouselContainer fullScreenWidth={fullScreenWidth}>
            <Carousel
                items={[{}, {}, {}, {}]}
                itemComponent={CarouselItemSkeleton}
                currentCardsNumberInSlides={4}
                fullScreenWidth={fullScreenWidth}
                container={"Playlist"}
            />
            </CarouselContainer>
        </div>
    );
}

export default SinglePlaylistSkeleton;
