import React from "react";

import styled from "@emotion/styled";
import { formatEntryThumbnailUrl } from "@mediaspace/shared/utils";

interface Props {
    className?: string;
    thumbnailUrl: string;
    blurred?: boolean;
    alt?: string;
}

const StyledBackground = styled("div")<{ blurred?: boolean }>(({ theme, blurred }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    ...(blurred && {
        filter: "blur(20px)",
        left: -20,
        right: -20,
        bottom: -20,
    }),
}));

/*
 * Renders entry thumbnail as a background of the parent element.
 * It should be used when the parent element size is dynamic.
 * When the element size is known, then it's better to use <img> tag with formatEntryThumbnailUrl() instead.
 */
export const BackgroundThumbnail: React.FC<Props> = ({
    className,
    thumbnailUrl,
    blurred,
    alt = ""
}) => {
    return (
        <StyledBackground
            className={className}
            blurred={blurred}
            {...(alt && { 'aria-label': alt, 'role': 'img' })}
            style={{
                backgroundImage:
                    "url(" +
                    formatEntryThumbnailUrl(thumbnailUrl, "auto", "auto") +
                    ")",
            }}
        />
    );
};

export default BackgroundThumbnail;
